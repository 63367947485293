import React, { useRef } from "react"
import PropTypes from "prop-types"

import CatalogHeader from "./CatalogHeader"
import "./ticktockinc.css"
import "./tailwind.css"

import withAuthentication from "./Session/withAuthentication"

const Layout = ({ children }) => {
  return (
    <div>
      {/* <CatalogHeader /> */}
      <section className="body">
        <main className="catalog-container">{children}</main>
        {/* <footer className="footer no-print">
          <div className="container">
            <h3 className="is-size-5">
              240 Moonachie Ave. Suite D. Moonachie, NJ
            </h3>

            <h3 className="is-size-5">
              Phone: 201-853-9200 | Fax: 201-853-9477 | Email: tiktok@att.net
            </h3>
            <br />
            <h3 className="is-size-4">
              {" "}
              <strong>
                {" "}
                © {new Date().getFullYear()}, Tick Tock Inc.{" "}
              </strong>{" "}
            </h3>
            <br />
            <small>
              Website designed & developed by{" "}
              <a href="https://shamdasani.org">shamdasani.org</a>{" "}
            </small>
          </div>
        </footer> */}
      </section>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withAuthentication(Layout)
