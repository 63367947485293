import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import CatalogLayout from "../../components/CatalogLayout"
import SEO from "../../components/seo"
import CatalogProductCell from "../../components/Product/CatalogProductCell"

const Home = ({ data }) => {
  let allProducts = data.allGoogleSpreadsheetSheet1.nodes.sort((a, b) =>
    a.itemCode > b.itemCode ? 1 : -1
  )
  let products = splitArrayIntoChunksOfLen(allProducts, 25)

  return (
    <CatalogLayout>
      <SEO
        title="Tick Tock Inc."
        description="Importer and Distributor of General Merchandise in the Greater New York City Area."
      />

      <div id="printPage">
        {products.map((subProducts, index) => (
          <>
            {index === 3 ? (
              <div className="border h-40 p-2 mt-2">
                <b className="text-blue-600">Tick Tock Inc.</b> <br />
                240 Moonachie Ave. Suite 400, <br />
                Moonachie, NJ 07424
              </div>
            ) : (
              <header className={`py-3 ${index == 0 && "text-center"}`}>
                <h1 className="font-extrabold text-3xl text-blue-600">
                  Tick Tock Inc.
                </h1>
                <b>
                  Importers & Distributors of General Merchandise | Est. 1987
                </b>

                <p>
                  240 Moonachie Ave. Suite 400, Moonachie, NJ | 201-853-9200 |
                  tiktok@att.net
                </p>
              </header>
            )}

            <div className="grid grid-cols-5">
              {subProducts.map((product, subIndex) => (
                <>
                  <CatalogProductCell product={product} />
                </>
              ))}
            </div>

            {index !== 3 && (
              <footer className="py-3 font-bold">
                Call to place an order today! Free delivery within the New
                Jersey and New York Area.
              </footer>
            )}
          </>
        ))}
      </div>
    </CatalogLayout>
  )
}

function splitArrayIntoChunksOfLen(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }
  return chunks
}

export const query = graphql`
  query CatalogQuery {
    allGoogleSpreadsheetSheet1(
      filter: {
        itemCode: {
          in: [
            "C 21"
            "P 21"
            "P 32AC"
            "D 55"
            "F 56"
            "F 57"
            "P 60"
            "N 61"
            "P 35"
            "S 69"
            "G 67"
            "A 19"
            "E 15"
            "C 38"
            "C 26"
            "B 18"
            "G 50-1"
            "G 51"
            "D 70"
            "D 71"
            "K 36"
            "L 29"
            "S 25-1"
            "S 40"
            "A 03"
            "A 06"
            "D 36"
            "D 37"
            "D 38"
            "D 39"
            "D 01"
            "D 02"
            "D 03"
            "D 04"
            "D 05"
            "D 06"
            "C 10"
            "C 10P"
            "C 10S"
            "C 47"
            "C 48"
            "A 33"
            "A 25"
            "A 21"
            "S 12"
            "S 59"
            "C 28"
            "C 28B"
            "G 34"
            "G 35"
            "G 21"
            "J 63"
            "J 61"
            "J 62"
            "J 66"
            "J 67"
            "J 68"
            "J 70"
            "J 71"
            "J 72"
            "J 73"
            "J 74"
            "J 75"
            "J 76"
            "J 77"
            "J 78"
            "J 73"
            "J 80"
            "J 85"
            "J 82"
            "J 83"
            "J 84"
            "S 35-1"
            "R 27"
            "R 28"
            "R 29"
            "R 30"
            "S 54"
            "S 18-2"
            "L 85"
            "G 89"
            "G 90"
            "G 61"
            "G 62"
            "G 63"
            "G 64"
            "G 65"
            "G 66"
            "C 41"
            "B 64"
            "B 65"
            "S 01"
            "L 22"
            "Q 89"
            "Q 73"
            "Q 71"
            "Q 93"
            "Q 94"
            "Q 90"
            "Q 91"
            "Q 92"
            "S 41"
          ]
        }
      }
    ) {
      nodes {
        category
        subcategory
        itemCode
        packing
        price
        price2
        description
      }
    }
  }
`

export default Home
