import React, { useState } from "react"
import { Link } from "gatsby"

const ProductCell = ({ product, subIndex }) => {
  let itemCode = product.itemCode
  let path = itemCode.replace(/\s+/g, "-").toLowerCase()
  let prioritizedPrice = product.price2 ?? product.price
  let price = (Math.round(prioritizedPrice * 100) / 100).toFixed(2)

  return (
    <div
      className={`${[0, 5, 10, 15, 20].includes(subIndex) && "border-l"} ${
        [4, 9, 14, 19, 24].includes(subIndex) && "border-r"
      } ${[0, 1, 2, 3, 4].includes(subIndex) && "border-t"} 
      ${[20, 21, 22, 23, 24].includes(subIndex) && "border-b"}`}
    >
      <div className="max-w-sm w-full lg:max-w-full lg:flex border">
        <div className="p-1 catalog-card-generated">
          <div className="flex flex-col justify-between h-full">
            <div>
              <h1 className="font-bold tracking-tight text-blue-600 text-center">
                {product.itemCode}
              </h1>
              <h1 className="text-center tracking-tight flex-1 text-xs font-bold justify-right capitalize mx-1">
                {product.description}
              </h1>
            </div>

            <div className="flex-end">
              <figure className="flex-1 product-image-small">
                <img src={`/products/${path}.png`} />
              </figure>

              <div className="flex justify-between text-xs">
                <h1 className="tracking-tight">
                  <b className="">{product.packing}</b>/cs
                </h1>
                <h1 className="price-catalog font-bold bg-blue-800 tracking-tight">
                  ${price}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCell
